import { useApolloClient } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import { once, isEmpty } from 'lodash';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { HISTORY, SUBSCRIBE_EVENTS, SUBSCRIBE_MESSAGES } from '../../actions/Messages';

export const GET_USER = gql`
  query getChatUser(
    $channelId: ID!
    $chatUserId: ID
    $email: String
    $firstname: String
    $lastname: String
    $language: String
    $mobile: String
    $phone: String
    $attributes: [InputChatUserAttribute!]
    $userAttributesToken: String
  ) {
    user: getChatUser(
      chatUserId: $chatUserId
      channelId: $channelId
      email: $email
      firstname: $firstname
      lastname: $lastname
      language: $language
      mobile: $mobile
      phone: $phone
      attributes: $attributes
      userAttributesToken: $userAttributesToken
    ) {
      id
    }
  }
`;

const INIT_CHAT = gql`
  query initChatUserChat($chatUserId: ID!, $channelId: ID!, $skills: [ID!]) {
    initChatUserChat(chatUserId: $chatUserId, channelId: $channelId, skills: $skills)
  }
`;

const CHECK_USER = gql`
  query doesChatUserExist($chatUserId: ID!, $channelId: ID!, $persistentSlug: String) {
    exist: doesChatUserExist(chatUserId: $chatUserId, channelId: $channelId, persistentSlug: $persistentSlug)
  }
`;

const CheckUser = ({
  data = { user: {} },
  slug,
  client,
  subscribeToMore,
  channelId,
  persistor,
  session,
  children,
  attributes,
  skills,
}) => {
  const messagesRef = useRef([]);
  const [isTyping, setIsTyping] = useState(false);
  const chatUserId = data.user.id || localStorage.getItem(`chatUserId_${channelId}`);

  const subscribe = useMemo(
    () => () => {
      if (!chatUserId) return;

      subscribeToMore({
        document: SUBSCRIBE_MESSAGES,
        variables: { chatUserId },
        updateQuery: (_, { subscriptionData: { data = {} } = {} }) => {
          if (
            data &&
            data.messageAdded &&
            ['channels', 'bot', 'users'].includes(data.messageAdded.authorType) &&
            (document.visibilityState === 'hidden' ||
              localStorage.getItem('window_focus') === 'false' ||
              localStorage.getItem('webchat_open') === 'false')
          ) {
            window.parent.postMessage(JSON.stringify({ action: 'new_messages' }), '*');
          }

          if (data.messageAdded) {
            setIsTyping(data.messageAdded.authorType === 'chatUsers' && !data.messageAdded.isAgentAssigned);
          }

          let historyResult;
          try {
            historyResult = client.readQuery({
              query: HISTORY,
              variables: { channelId, chatUserId },
            });
          } catch (e) {
            // Error handling (optional)
          }

          if (historyResult && historyResult.history) {
            const prevMessages = messagesRef.current.slice();
            messagesRef.current = [];
            try {
              const newHistory = [
                ...(historyResult.history || []),
                ...prevMessages.filter(message => !historyResult.history.some(hMessage => hMessage.id === message.id)),
                data.messageAdded,
              ];
              client.writeQuery({
                query: HISTORY,
                variables: { channelId, chatUserId },
                data: { history: newHistory },
              });
            } catch (e) {
              // Error handling (optional)
            }
          } else {
            messagesRef.current = messagesRef.current.concat(data.messageAdded);
          }
        },
      });
      subscribeToMore({
        document: SUBSCRIBE_EVENTS,
        variables: { chatUserId },
        updateQuery: (_, { subscriptionData: { data: { eventAdded = {} } = { eventAdded: {} } } = {} }) => {
          setIsTyping(isTyping => (eventAdded.isTyping && !isTyping ? true : isTyping));
        },
      });
    },
    [chatUserId]
  );
  useEffect(subscribe, [subscribe]);

  return (
    <Query query={INIT_CHAT} variables={{ chatUserId: chatUserId, channelId: channelId, skills }}>
      {({ loading }) => {
        if (loading) return null;
        return (
          <Query
            fetchPolicy="network-only"
            query={CHECK_USER}
            variables={{
              chatUserId,
              channelId: channelId,
              persistentSlug: slug,
            }}
          >
            {({ data: exist, loading, error }) => {
              if (loading) return null;
              if (error || (exist && !exist.exist)) {
                persistor.pause();
                persistor.purge().then(() => window.location.reload());
                return <span>ChatUser in cache does not exist, clear localstorage</span>;
              }
              if (data.user.id) {
                localStorage.setItem(`chatUserId_${channelId}`, data.user.id);
              }
              return (
                <Fragment>
                  {children({ children, user: data.user, isTyping, messagesFromSubscription: messagesRef.current })}
                </Fragment>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

const emitUserId = once(id => {
  window.parent.postMessage(JSON.stringify({ action: 'user-created', id }), '*');
});

export default ({
  slug,
  channelId,
  persistor,
  children,
  chatUserId: propsChatUserId,
  session = {},
  saveHistory = true,
  subscribeToMore,
}) => {
  const apolloClient = useApolloClient();
  const browserLocale =
    session.locale || (navigator.languages !== undefined ? navigator.languages[0] : navigator.language || 'DEFAULT');
  const browersLocaleShort = browserLocale.substr(0, 2).toUpperCase();
  const attributes = useMemo(() => {
    const {
      firstname,
      lastname,
      locale,
      email,
      mobile,
      telephone,
      skills,
      context,
      authToken,
      userAttributesToken,
      ...remainingSession
    } = session || {};
    if (!!remainingSession && !isEmpty(remainingSession)) {
      return Object.entries(remainingSession).reduce((arr, [key, value]) => {
        return arr.concat({ key, value });
      }, []);
    }
  }, [session]);

  const chatUserId = propsChatUserId || localStorage.getItem(`chatUserId_${channelId}`);

  console.log('Storage chatUser::', chatUserId);

  return (
    <Query
      query={GET_USER}
      fetchPolicy="network-only"
      variables={{
        channelId,
        chatUserId: chatUserId,
        email: session.email,
        firstname: session.firstname,
        lastname: session.lastname,
        language: browersLocaleShort || null,
        mobile: session.mobile,
        phone: session.telephone,
        attributes,
        userAttributesToken: session.userAttributesToken,
      }}
    >
      {getUserProps => {
        if (getUserProps.loading) return null;
        if (getUserProps.error) return <div>Error fetching user</div>;
        const newChatUserId = getUserProps.data && getUserProps.data.user && getUserProps.data.user.id;

        console.log('is new chat user::', newChatUserId !== chatUserId, newChatUserId, chatUserId);

        emitUserId(newChatUserId);

        return (
          <CheckUser
            {...getUserProps}
            client={apolloClient}
            loading={false}
            channelId={channelId}
            persistor={persistor}
            session={session}
            children={children}
            subscribeToMore={subscribeToMore}
            slug={slug}
            attributes={attributes}
            skills={session.skills}
          />
        );
      }}
    </Query>
  );
};
